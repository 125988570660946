import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import useAuth from './hooks/useAuth';
// import useSettings from './hooks/useSettings';
import SplashScreen from './components/SplashScreen';
import routes from './routes';
import { gtmConfig } from './config';
import gtm from './utils/gtm';
import { Theme } from '@carbon/react';
import './index.scss';
import './app.scss';
import './i18n';

function App() {
  const content = useRoutes(routes);
  const auth = useAuth();
  // const { settings } = useSettings();

  // return content;

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <Theme
      style={{ height: '100%' }}
      theme="g10"
    >
      {auth.isInitialized ? content : <SplashScreen />}
    </Theme>
  )

  // return auth.isInitialized ? content : <SplashScreen />
}

export default App;
