import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const textMap = {
  rpcfast: {
    translation: {
      // --- Dashboard Page --- 
      'Header title': 'RPCFAST',
      'Footer terms of service': '© 2022 · RPCFast  Terms of Service',
      // Free Cards
      'Free Card1 tariff name': 'FREE',
      'Free Card1 button text': 'Upgrade',
      'Free Card2 title': 'Response time',
      'Free Card2 avg text': 'avg 85.6 ms',
      'Free Card3 title': 'Request / sec',
      'Free Card3 avg text': 'Limit 15',
      'Free Card4 title': 'Compute Units / mon',
      // Growth Cards
      'Growth Card1 tariff name': 'Growth',
      'GrowthCard1 button text': 'Upgrade',
      'Growth Card2 title': 'Response time',
      'Growth Card2 avg text': 'avg 85.6 ms',
      'Growth Card3 title': 'Compute Units / mon',
      // Modal Create App
      'Create App title': 'Create App',
      'Field name placeholder': 'My app',
      'Field name description': 'Short description',
      // Notification 
      'Success app create notification': 'App has been successfully created',
      'Error app create notification': 'TODO!!!',
      // --- Apps ---
      'Apps block title': 'RPCFAST',
      'Create app button': 'Create app',
      // --- How to start
      'How to start block title': 'How to start',
      'How to start Guides link name': 'Guides',
      'How to start Guides link': 'https://docs.rpcfast.com/guides/intro',
    }
  },
//   fr: {
//     translation: {
//       'Welcome to React': 'Bienvenue à React et react-i18next'
//     }
//   }
};

i18n
  .use(initReactI18next)
  .init({
    resources: textMap,
    lng: 'rpcfast',
    fallbackLng: 'rpcfast',
    interpolation: {
      escapeValue: false
    }
  });


export const RPCFastAppsTableHeader = [
    {name: 'App'}, 
    {name: 'Labels', style: { minWidth: '105px' }}, 
    {name: 'Product'}, 
    {name: 'API key'}, 
    {name: 'Https endpoint'}, 
    {name: 'Websocket endpoint'}
];

export const RPCFastAppsRowSchema = {
  fieldKeyName: 'api_key',
  schema: [
    {type: 'link', link: '/app/%api_key%', fieldValueName: 'name', replace_re: /%api_key%/gi}, 
    {type: 'tags', fieldValueName: 'product.label'}, 
    {type: 'str_value', fieldValueName: 'product.name'}, 
    {type: 'copy_snippet', fieldValueName: 'api_key', cutLength: 25}, 
    {type: 'copy_snippet', fieldValueName: 'https_endpoint', cutLength: 25},
    {type: 'copy_snippet', fieldValueName: 'websocket_endpoint', cutLength: 25},
  ]
};


// let re = /apples|bananas/gi;
// import { textSources } from '../../../i18n';
// console.log(333333, textSources.en.translation['Apps table'][0].qwe);