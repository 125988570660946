import { useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { Button } from '@carbon/react';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';

const LoginAuth0 = (props) => {
  const mounted = useMounted();
  const { loginWithRedirect, user } = useAuth();
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      // await loginWithPopup();
      await loginWithRedirect({
        screen_hint: 'login'
      });
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setError(err.message);
      }
    }
  };

  const handleSignUp = async () => {
    try {
      // await loginWithPopup();
      await loginWithRedirect({
        screen_hint: 'signup'
      });
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setError(err.message);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}
    >
      {error && (
        <Box sx={{ my: 3 }}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          onClick={handleLogin}
          variant="contained"
        >
          Log In
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          onClick={handleSignUp}
          variant="contained"
        >
          Sign Up
        </Button>
      </Box>
    </div>
  );
};

export default LoginAuth0;
