import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken';
axiosInstance.defaults.xsrfCookieName = 'csrftoken';
if (process.env.REACT_APP_BASE_API_URL !== undefined) {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(
    (error.response && { data: error.response.data, status: error.response.status }) || new Error('Something went wrong')
  )
);

export default axiosInstance;
