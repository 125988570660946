import { createContext, useEffect, useReducer } from 'react';
import { useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import axios from '../utils/axios';
import Cookies from 'js-cookie';
// import axiosRetry from 'axios-retry';
import gtm from '../utils/gtm';

const retry = require('async-retry');

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  configSiteData: {}
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, configSiteData } = action.payload;
    
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      configSiteData
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isInitialized: false,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();

  const getUser = async () => {
    // Make your calls, run your functions...
    // If the call throws exception, it will be caught
    // and `async-retry` will retry your function `f`
    const response = await axios.get('/api/v1/auth0-users/');

    // The actual successful API call has response.data.total = 12, but we will
    // purposely throw an exception to trigger a retry based on business logic
    // if (response.data.total == 12) {
    //   throw new Error('We know it is 12. But we throw anyways')
    // }

    // If everything went ok, return your results
    return response
  };

  const retryConfig = {
    retries: 3,
    minTimeout: 1000,
    maxTimeout: 3000,
    randomize: false
    // onRetry: (err) => console.error(err.message)
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        // console.log(auth0Config);
        // useRefreshTokens: true,
        auth0Client = new Auth0Client({
          cacheLocation: 'localstorage',
          useCookiesForTransactions: true,
          ...auth0Config
        });

        // await auth0Client.checkSession();

        if (searchParams.get('code') && searchParams.get('state')) {
          // this works only once, if refresh page Error: Invalid state occurs
          try {
            const { appState } = await auth0Client.handleRedirectCallback();
            const userAuth0 = await auth0Client.getUser();
            
            if (userAuth0[process.env.REACT_APP_AUTH0_RULE_AUTH_TYPE_KEY] === 'signup') {
              gtm.push({ 'event': 'Registration' });
              // console.log('Registration tracked');
            } else if (userAuth0[process.env.REACT_APP_AUTH0_RULE_AUTH_TYPE_KEY] === 'login') {
              gtm.push({ 'event': 'LogIn' });
              console.log('LogIn tracked');
            }
          } catch (err) {
            console.error('error', err);
          }
        }

        const isAuthenticated = await auth0Client.isAuthenticated();
         // console.log('isAuthenticated', isAuthenticated);
         console.log("isAuthenticated", isAuthenticated); 

        if (isAuthenticated) {
          const accessToken = await auth0Client.getTokenSilently();
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          axios.defaults.headers.common['Content-Type'] = 'application/json';

          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          // If everything goes well, you can retrieve the returned value from `f`
          const response = await retry(getUser, retryConfig);

          // const response = await axios.get('/api/v1/auth0-users/');

          // const { user } = response.data;
          const user = response.data;
          
          try {
            const ga_cid = Cookies.get('_ga');
            if (ga_cid) {
              if (ga_cid !== window.localStorage.getItem('ga_cid')) {
                window.localStorage.setItem('ga_cid', ga_cid);
                await axios.patch('/api/v1/auth0-users/update-ga-cid/', {ga_cid});
              }
            }
          } catch (err) {
            console.error('error', err);
          }

          const {configSiteData} = await import(`settings/${process.env.REACT_APP_SETTINGS}`);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user,
              configSiteData
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
              configSiteData: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            configSiteData: null
          }
        });
      }
    };

    initialize();
  }, []);


  const getUserHandler = async () => {
    const accessToken = await auth0Client.getTokenSilently();
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    const response = await axios.get('/api/v1/auth0-users/');
    return response; 
  }

  const loginWithPopup = async (options) => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      // const user = await auth0Client.getUser();
      // console.log(user);

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      const accessToken = await auth0Client.getTokenSilently();
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Content-Type'] = 'application/json';

      const response = await retry(getUser, retryConfig);
      // const response = await axios.get('/api/v1/auth0-users/');
      // const { user } = response.data;
      const user = response.data;

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }
  };

  const loginWithRedirect = async (options) => {
    await auth0Client.loginWithRedirect(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      // const user = await auth0Client.getUser();
      // console.log(user);

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      const accessToken = await auth0Client.getTokenSilently();
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Content-Type'] = 'application/json';

      const response = await retry(getUser, retryConfig);
      // const response = await axios.get('/api/v1/auth0-users/');
      // const { user } = response.data;
      const user = response.data;

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout({ returnTo: 'https://distancematrix-ai.webflow.io/' });
    delete axios.defaults.headers.common.Authorization;
    dispatch({
      type: 'LOGOUT'
    });
  };

  const gt = () => {

  }

  const getAccessToken = async () => auth0Client.getTokenSilently();
  const handleRedirectCallback = async () => auth0Client.handleRedirectCallback();

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        loginWithRedirect,
        logout,
        handleRedirectCallback,
        getAccessToken,
        getUserHandler
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
