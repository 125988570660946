import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
// import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
// import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
// import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
  // <Component {...props} />
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const Main = Loadable(lazy(() => import('./pages/dashboard/Main')));
const AppDetail = Loadable(lazy(() => import('./pages/dashboard/AppDetail')));
const AppSettings = Loadable(lazy(() => import('./pages/dashboard/AppSettings')));
const Billing = Loadable(lazy(() => import('./pages/dashboard/Billing')));
const UpgradeAccount = Loadable(lazy(() => import('./pages/dashboard/UpgradeAccount')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const PaymentStatus = Loadable(lazy(() => import('./pages/dashboard/PaymentStatus')));
const StreamingAPI = Loadable(lazy(() => import('./pages/dashboard/StreamingAPI')));
const StreamingAPIDetail = Loadable(lazy(() => import('./pages/dashboard/StreamingAPIDetail')));
const StreamingAPISettings = Loadable(lazy(() => import('./pages/dashboard/StreamingAPISettings')));

const routes = [
  {
    path: '/login/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard/" />
      },
      {
        path: 'dashboard',
        element: <Main />
      },
      {
        path: 'app/:appId',
        element: <AppDetail />
      },
      {
        path: 'app/:appId/settings',
        element: <AppSettings />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'billing-and-usage',
        element: <Billing />
      },
      {
        path: 'upgrade-account',
        element: <UpgradeAccount />
      },
      {
        path: 'payment-status',
        element: <PaymentStatus />
      },
      // {
      //   path: 'streaming-api',
      //   element: <StreamingAPI />
      // },
      // {
      //   path: 'streaming-api/:streamingAPIId',
      //   element: <StreamingAPIDetail />
      // },
      // {
      //   path: 'streaming-api/:streamingAPIId/settings',
      //   element: <StreamingAPISettings />
      // },
    ]
  },
  {
    path: '*',
    element: <Navigate to="/login/" />
  }
  // {
  //   path: '*',
  //   element: <MainLayout />,
  //   children: [
  //     {
  //       path: '401',
  //       element: <AuthorizationRequired />
  //     },
  //     {
  //       path: '404',
  //       element: <NotFound />
  //     },
  //     {
  //       path: '500',
  //       element: <ServerError />
  //     },
  //     {
  //       path: '*',
  //       element: <NotFound />
  //     }
  //   ]
  // }
];

export default routes;
