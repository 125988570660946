export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  issuer: process.env.REACT_APP_AUTH0_ISSUER,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};